
export const weekdays = [
    { id: "D1", name: "Lunes", },
    { id: "D2", name: "Martes", },
    { id: "D3", name: "Miércoles", },
    { id: "D4", name: "Jueves", },
    { id: "D5", name: "Viernes", },
];

export const weekenddays = [
    { id: "D6", name: "Sábado", },
    { id: "D7", name: "Domingo", },
];